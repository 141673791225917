<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import SubscriptionContent from "@/components/Intro/SubscriptionContent.vue";

const {t} = useI18n();

const router = useRouter();

const submit = (subscriptionType: string) => {
  router.push({
    name: 'introInvoice',
    query: {
      subscriptionType,
    }
  })
}

</script>

<template>
  <CCard class="p-3  mt-3">
    <CCardBody>
      <h2>{{ $t('intro.chooseSubscription') }}</h2>
      <div v-html="$t('intro.subscription.html')"></div>

      <SubscriptionContent @submit="submit"/>
      <CButton class="my-0 mx-auto d-flex" @click="$router.push({ name: 'introWelcome' })">{{ $t('intro.back') }}</CButton>

    </CCardBody>
  </CCard>
</template>

<style scoped lang="scss">

</style>