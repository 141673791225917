<script setup lang="ts">
import {useRouter} from "vue-router";
import {ref} from 'vue'
import Divider from "@/components/Divider.vue";
import {supabase} from "@/supabase.ts";
import {CButton} from "@coreui/vue";

const email = ref('');
const errorEmail = ref('');
const finished = ref(false)

const router = useRouter();
const handleForgotPassword = async () => {
  const {data, error} = await supabase.auth.resetPasswordForEmail(email.value);


  if (error) {
    errorEmail.value = error.message;
    console.error(error);
  } else {
    finished.value = true;
  }
}

</script>

<template>
  <CContainer fluid class="auth justify-content-center d-flex flex-column align-items-center">
    <img class="logo mb-5" alt="logo" src="/logo-black.png"/>

    <CCard class="auth__card">
      <CCardBody>
        <CCardTitle class="text-center mb-4">Reset hesla</CCardTitle>
        <CForm @submit.prevent="handleForgotPassword"
               v-if="!finished"
               class="d-flex flex-column">
          <CAlert class="w-100" v-if="errorEmail.length > 0" color="danger">{{ errorEmail }}</CAlert>
          <CFormInput
              v-model="email"
              class="mb-2"
              type="email"
              id="exampleFormControlInput1"
              :label="$t('user.email')"
              placeholder="jiri.novak@seznam.cz"
              aria-describedby="exampleFormControlInputHelpInline"
          />

          <CButton class="w-100" size="lg" color="primary" type="submit">Odeslat</CButton>
          <CFormText>
            <router-link class="link-back" to="/sign-in">Zpět</router-link>
          </CFormText>
        </CForm>

        <div v-else class="d-flex align-items-center flex-column">
          <p>{{ $t('auth.forgotPassword.success') }}</p>
          <CButton color="primary" @click="$router.push({ name: 'signIn' })">{{
              $t('auth.forgotPassword.signIn')
            }}
          </CButton>
        </div>
      </CCardBody>
    </CCard>
  </CContainer>
</template>

<style lang="scss">

</style>
