<script setup lang="ts">

import {ref} from "vue";
import {
  CContainer,
} from "@coreui/vue";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/user.ts";

const {user} = storeToRefs(useUserStore());

const step = ref(1);

</script>

<template>
  <CContainer fluid class="subscription d-flex flex-column align-items-center">
    <div class="subscription__content">
      <router-view></router-view>
    </div>
  </CContainer>
</template>

<style lang="scss">

.subscription {
  height: 100vh;
  background: #f4f4f4;
  $opacity: 0.8;
  //background: linear-gradient(0deg, rgba(255, 255, 255, 1), rgba(255, 255, 255, $opacity), rgba(255, 255, 255, $opacity)), url(/patternpad.svg);

  background-size: cover;
  min-height: 100%;

  &__content {
    max-width: 1200px;
    width: 100%;

    .icon {
      min-width: 1.5rem;
    }
  }

  .price {
    &-value {
      font-size: 30px;
    }
  }

  &.close-disabled {
    .btn-close {
      display: none;
    }
  }

  .divider {
    margin: 0 5px;
  }

  &__promo-list {
    list-style: none;
    padding: 0;

    li {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      .icon {
        color: var(--cui-primary);
        margin-right: 5px;
      }
    }
  }

}
</style>