<script setup lang="ts">
import {useI18n} from "vue-i18n";
import {CCol, CContainer, CRow} from "@coreui/vue";
import {CIcon} from "@coreui/icons-vue";
import {defineComponent} from "vue";

const {t} = useI18n();
const {header, subheader} = defineProps<{
  header: string,
  subheader: string
}>();

</script>

<template>
  <CContainer fluid class="auth h-100">
    <CRow class="h-100 w-100 mx-0">
      <CCol
          :xs="{ span: 12, order: 2 }"
          :sm="{ span: 12, order: 2 }"
          :lg="{ span: 6, order: 1 }"
          class="d-flex auth__info-section h-100 justify-content-center">
        <CContainer fluid class="d-flex justify-content-center">
          <div class="d-flex flex-column auth__content mt-5 justify-content-between h-max--60 h--60">
            <section>
              <h1 class="font-weight--700 d-flex align-items-center">
                <img class="logo-icon" src="/public/logo-icon-white.png" alt="bluestream logo"/>
                <span>{{ $t('auth.info.welcome') }}</span>
              </h1>
              <p class="font-size--20">{{ $t('auth.info.welcome.text') }}</p>
            </section>
            <section class="auth__extra-info mt-5">
              <h2>{{ $t('auth.info.welcome.promo.header') }}</h2>
              <ul class="mt-5">
                <li class="d-flex align-items-center mb-3"
                    v-for="i in 2"
                >
                  <CIcon class="mx-2" size="xl" icon="cilCheckAlt"/>
                  {{ $t(`auth.info.welcome.promo.${i}`) }}
                </li>
              </ul>
            </section>
          </div>
        </CContainer>
        <img class="robot" src="/public/robot.png" alt="AI robot"/>
      </CCol>

      <CCol
          :xs="{ span: 12, order: 1 }"
          :sm="{ span: 12, order: 1 }"
          :lg="{ span: 6, order: 2 }"
          class="auth__form-section d-flex flex-column align-items-center justify-content-center">
        <div class="auth__card">
          <img class="logo mb-5" alt="logo" src="/logo-black.png"/>
          <h5 class="mb-1 color-primary"><strong>{{ subheader }}</strong></h5>
          <h1 class="mb-4">{{ header }}</h1>
          <slot/>
        </div>
      </CCol>
    </CRow>
  </CContainer>
</template>

<style lang="scss">

$baseClass: '.auth';

#{$baseClass} {
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw !important;
  max-width: 100% !important;

  h1 {
    white-space: nowrap;
  }

  &__info-section {
    background-repeat: no-repeat;
    background-position: bottom left;
    background-color: var(--cui-primary);
    color: white;
    position: relative;
    box-shadow: 3px 0 4px 0 rgba(0, 0, 0, 0.12);

    .auth__content {
      width: 100%;
      max-width: 580px;
      padding: 20px;

      ul {
        font-size: 30px;
        list-style: none;

      }
    }

    .robot {
      position: absolute;
      opacity: 0.09;
      bottom: 0;
      left: 0;
      width: 50%;
      pointer-events: none;
    }
  }

  &__form-section .card {
    max-width: 500px;
  }

  .icon {
    min-width: 30px !important;
    width: 30px !important;
    height: 30px !important;
  }

  .logo {
    width: 220px;

    &-icon {
      width: 50px;
      margin-right: 10px;
    }
  }

  & &__card {
    width: 100%;
    max-width: 400px;
    padding: 20px 10px 40px;

    .card-body {
      width: 100%;
    }
  }
}

@media (max-width: 1024px) {
  :root #{$baseClass} {
    & &__card {
      min-width: 200px;
      width: 100%;
    }

    &__content {
      margin-top: 0 !important;
    }

    &__form-section {
      padding-top: 40px;
      height: 80% !important;
      min-height: 600px;
      max-height: 80% !important;
    }
  }
}

@media (max-width: 768px) {
  .auth__info-section,
  .auth__content {
    padding: 0 !important;
    justify-content: start !important;
  }
  .auth__extra-info {
    margin-bottom: 60px;
  }

}

</style>
