<script setup lang="ts">

import {storeToRefs} from "pinia";
import NavigationLinks from "@/components/Navigation/NavigationLinks.vue";
import {useUserStore} from "@/store/user.ts";
import {computed, ref} from "vue";
import {NavigationLink} from "@/components/Navigation/NavigationLinks.vue";
import {useI18n} from "vue-i18n";
import {useSubscriptionStore} from "@/store/subscription.ts";

const userStore = useUserStore();
const { subscription, hasSubscription } = storeToRefs(useSubscriptionStore());

const {t} = useI18n()
const {user} = storeToRefs(userStore);


const navItems = computed<Array<NavigationLink>>(() => ([
  {
    name: 'settingsProfile',
    label: t(`settings.links.profile`),
  },
  // {
  //   name: 'settingsTokens',
  //   label: t(`settings.links.tokens`),
  //   show: Object.keys(subscription).length > 0,
  // },
  {
    name: 'settingsSubscription',
    label: t(`settings.links.subscription`),
    show: Object.keys(subscription).length > 0,
  },
  {
    name: 'settingsSupport',
    label: t(`settings.links.support`),
  }
]))

</script>

<template>
  <CContainer class="settings min-vh-100 mb-3">
    <CCard class="w-100 h-min-100">
      <CCardBody>
        <NavigationLinks :nav-items="navItems"/>
        <router-view/>
      </CCardBody>
    </CCard>
  </CContainer>
</template>

<style lang="scss">
  .settings {
  }
</style>
