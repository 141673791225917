<script setup lang="ts">
import {useRouter} from "vue-router";
import {ref} from "vue";
import {storeToRefs} from "pinia";
import {CCard, CCardBody, CCol, CRow, CTableBody} from "@coreui/vue";
import PurchaseTokenModal from "@/components/Settings/Subscription/Modals/PurchaseTokenModal.vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useSubscriptionUtils} from "@/composables/useSubscriptionUtils.ts";
import {postRequest} from "@/services/http.service.ts";

const router = useRouter();
const visiblePurchaseTokenModal = ref(false);
const subscriptionStore = useSubscriptionStore();
const {subscription} = storeToRefs(subscriptionStore);

const openStripeAccount = async () => {
  const stripePortalResponse = await postRequest(`stripe/customer/portal`);
  const {url: portalUrl} = await stripePortalResponse.json();

  window.location.href = portalUrl;
}

</script>

<template>
  <CRow :lg="{ cols: 3, gutter: 4 }">
    <CCol>
      <CCard class="h-100">
        <CCardBody class="d-flex flex-column">
          <h4>{{ $t('settings.subscription.edit.header') }}</h4>
          <p>{{ $t('settings.subscription.edit.text') }}</p>
          <CButton class="mx-2 mt-auto"
                   @click="openStripeAccount"
                   color="primary">{{ $t(`settings.subscription.edit.button`) }}
          </CButton>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>

</template>

<style scoped lang="scss">

.price {
  font-size: 28px;
}

.logo-cards {
  margin-top: auto;
  width: 150px;
}
</style>