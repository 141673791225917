<script setup lang="ts">
import {CCol, CRow} from "@coreui/vue";
import {useTokenStore} from "@/store/token.ts";
import Number from "@/components/Atoms/Number/Number.vue";
import {storeToRefs} from "pinia";

const { tokens } = storeToRefs(useTokenStore());
</script>

<template>
  <CRow class="h-100 align-items-center">
    <CCol class="d-flex w-25">
      <CIcon class="side-menu-icon side-menu-toggle"
             color="primary"
             size="lg" icon="cilHamburgerMenu"/>
      </CCol>
    <CCol class="d-flex text-white">
<!--      <span class="white-space-nowrap">{{ $t('ui.tokenCount') }}</span>&nbsp;<strong><Number :value="tokens"/></strong>-->
    </CCol>
    <CCol class="d-flex flex-grow-1 justify-content-end mx-2 text-white">
      <img class="logo" alt="logo" src="/logo.png"/>
    </CCol>
  </CRow>
</template>

<style scoped lang="scss">
  .side-menu-toggle {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 999;
    padding: 20px;
    cursor: pointer;
    color: white !important;

    $size: 70px;
    width: $size !important;
    height: $size !important;
    min-width: $size !important;
    min-height: $size !important;
  }

  .logo {
    width: 180px !important;
  }
</style>