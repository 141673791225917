<script setup lang="ts">

import SuggestionPrompt from "@/components/Atoms/SuggestionPrompt/SuggestionPrompt.vue";
import {useChatStore} from "@/store/chat.ts";

const { submitSuggestion } = useChatStore();

const { suggestions } = defineProps<{
  suggestions: string[],
  type: string,
}>();

</script>
<template>
  <CRow v-if="suggestions?.length" :xs="{ cols: 1 }" :sm="{ cols: 1 }" :md="{ cols: (suggestions?.length === 1 ? 1 : 2), gutter: 3 }" class="mb-5">
    <template v-for="(suggestion, index) in suggestions">
      <CCol class="mb-3 mt-1">
        <SuggestionPrompt class="h-min-100"
                          :id="`tour-suggestion-prompt-${index}`"
                          @submit="submitSuggestion"
                          :type="type"
                          :prompt="suggestion"/>
      </CCol>
    </template>
  </CRow>
</template>

<style lang="scss">

</style>