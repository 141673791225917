import {useI18n} from "vue-i18n";

export const useUtils = () => {
    const i18n = useI18n();

    const translationExists = (key) => Object.keys(i18n.messages.value.en).includes(key)


    return {
        translationExists,
    }
}

