export interface ITemplateInput {
    id: number;
    value: string;
    key: string;
    name: string;
    createdAt: string;
}

const DEFAULT = {
    id: 0,
    value: '',
    key: '',
    name: '',
    createdAt: '',
};

export class TemplateInput implements ITemplateInput {
    id: number;
    value: string;
    key: string;
    name: string;
    createdAt: string;

    constructor(params: {
        id: number;
        value: string;
        key: string;
        name: string;
        createdAt: string;
    } = DEFAULT) {
        this.id = params.id;
        this.value = params.value;
        this.key = params.key;
        this.name = params.name;
        this.createdAt = params.createdAt;
    }

    static fromDbResponse(dbResponse: any): ITemplateInput {
        if (dbResponse.length === 0) {
            return new TemplateInput({
                id: 0,
                value: '',
                key: '',
                name: '',
                createdAt: '',
            });
        }

        return new TemplateInput({
            id: dbResponse.id,
            value: dbResponse.response,
            key: dbResponse.template.key,
            name: dbResponse.name,
            createdAt: dbResponse.created_at,
        });
    }
}