export interface ITemplateFieldValue {
    fieldId: string;
    fieldName: string;
    value: string;
}

export class TemplateInputFieldValue implements ITemplateFieldValue {
    fieldId: string;
    fieldName: string;
    value: string;

    constructor(params: {
        fieldId: string;
        fieldName: string;
        value: string;
    }) {
        this.fieldId = params.fieldId;
        this.fieldName = params.fieldName;
        this.value = params.value;
    }

    static fromDbResponse(item: any): TemplateInputFieldValue {
        return new TemplateInputFieldValue({
            fieldId: item.template_input_field.id,
            fieldName: item.template_input_field.name,
            value: item.value,
        });
    };
}