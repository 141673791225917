import {computed} from "vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {storeToRefs} from "pinia";
import {useTokenStore} from "@/store/token.ts";

export function useSubscriptionAccess() {
    const {
        isTrial,
        product,
        hasSubscription,
        userSubscriptionFeatureList} = storeToRefs(useSubscriptionStore());
    const {hasImages} = storeToRefs(useTokenStore());

    const hasGeneralAccess = computed(() => {
        return isTrial?.value || hasSubscription.value;
    });

    const hasTrialImagesLeft = computed(() => {
        return isTrial?.value && !hasImages?.value
    });

    const hasFeatureImage = computed(() =>
        isTrial.value || (product.value && userSubscriptionFeatureList.value?.includes('image'))
    )

    const hasFeatureText = computed(() =>
        isTrial.value || (product.value && userSubscriptionFeatureList.value?.includes('text'))
    )

    return {
        hasGeneralAccess,
        hasTrialImagesLeft,
        hasFeatureImage,
        hasFeatureText,
    }
}