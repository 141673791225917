// @ts-ignore
import {IInvoiceDetails, postRequest} from "@/services/http.service.ts";
// @ts-ignore
import {SubscriptionId} from "@backend/functions/_shared/model/index.ts";

export async function createStripeSubscription(priceId, invoiceDetails?: IInvoiceDetails) {
    return postRequest(`stripe/subscription/create`, {
        priceId,
        ...invoiceDetails,
    });
}

export async function purchaseExtraTokens(priceId) {
    return postRequest(`stripe/token/purchase`, {
        priceId,
    });
}

export async function getPriceList() {
    return postRequest(`stripe/price/list`);
}

export async function getProductList() {
    return postRequest(`stripe/product/list`);
}

export async function getStripeSubscription() {
    return postRequest('stripe/subscription/get');
}

export async function getStripeProduct(productId) {
    return postRequest('stripe/product/get', {
        productId,
    });
}

export async function buyTokens(productId: string) {
    return postRequest('stripe/product/get', {
        productId,
    });
}