<script setup lang="ts">
import ChatPromptContainer from "@/components/templates/Chat/ChatPrompt/ChatPromptContainer.vue";
import ChatContent from "@/components/templates/Chat/ChatContent.vue";
import {useRoute} from "vue-router";
import {computed, provide} from "vue";
import {useChatStore} from "@/store/chat.ts";

const route = useRoute();
const chatId = computed(() => route.params.chatId);
const chatStore = useChatStore();

const chat = computed(() => chatStore.getChatListById(parseInt(chatId.value as string)));

provide('chatId', chatId);

</script>

<template>
  <ChatContent :key="chatId" :chatId="chatId" :chat="chat"/>
  <ChatPromptContainer :key="chatId" :chatId="chatId" :chat="chat"/>
</template>

<style scoped lang="scss">

</style>