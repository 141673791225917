<script setup lang="ts">
import Number from "@/components/Atoms/Number/Number.vue";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";

const {t} = useI18n();

const router = useRouter();

const {index, product, isFavourite} = defineProps<{
  index: Number,
  product: any,
  isFavourite: Boolean,
}>();

const emit = defineEmits(['submit']);

const submit = (priceId) => {
  emit('submit');
}

</script>

<template>
  <div class="product-card"
       :class="{
                'product-card--isFavourite': isFavourite
               }">
    <div class="product-card__highlight"
         v-if="isFavourite">
      {{ $t('ui.favourite') }}
    </div>

    <section class="product-card__content d-flex flex-column h-100">
      <section
          class="d-flex align-items-start align-items-md-center mb-4 flex-xl-column flex-lg-row justify-content-sm-between">
        <div>
          <h3>{{ product?.name }}</h3>
          <p v-html="$t(`subscription.description.${product?.name?.toLowerCase()}`)"></p>
        </div>

        <strong class="product-card__price">
          <Number :value="product?.price"/>
          <sup class="currency">&nbsp;{{ $t('general.currency.CZK') }}</sup>
          <span class="unit">/{{ $t('general.monthly') }}</span>
        </strong>
      </section>

      <section
          class="d-flex
          h-100
          justify-content-sm-between

          flex-column
          flex-sm-column
          flex-lg-row
          flex-xl-column
          align-items-xl-center"
      >
        <ul class="subscription__promo-list d-flex flex-column align-items-start">
          <li v-for="feature in product?.features">
            <CIcon size="xl" icon="cilCheckAlt"/>
            <span v-html="$t(feature.key, {value: feature.value})"></span>
          </li>
        </ul>

        <section class="text-center mt-auto">
          <CButton class="align-self-center mt-auto"
                   @click="submit"
                   color="primary">{{ $t('payment.subscription.select') }}
          </CButton>
        </section>
      </section>
    </section>
  </div>
</template>

<style scoped lang="scss">
.product-card {
  border: 3px solid #DDDDDDDD;
  border-radius: 10px;
  height: 100%;
  transition: background 0.15s;
  position: relative;

  h3 {
    font-size: 30px;
  }

  &--isFavourite {
    border-color: var(--cui-primary);

    .product-card__highlight {
      position: absolute;
      top: -45px;
      padding: 10px 10px 20px;
      width: calc(100% + 6px);
      background: var(--cui-primary);
      left: -3px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      color: white;
      text-align: center;
      text-transform: uppercase;
    }

    .product-card__content {
      margin-top: -7px;
      padding-top: 47px;
    }
  }

  &__content {
    position: relative;
    background: white;
    padding: 40px;
    border-radius: 5px;
    z-index: 99;
  }

  &--selected {
    border-color: var(--cui-primary);
  }

  h3 {
    color: black;
  }

  .icon {
    border-radius: 50%;
    background: var(--cui-primary);
    padding: 3px;
    color: white;
    margin-right: 10px;

    &.red {
      background: red;
    }
  }

  &__price {
    font-size: 55px;

    sup {
      font-size: 30px;
    }

    .unit {
      font-size: 16px;
      text-align: center;
      margin-top: -15px;
      font-weight: 300;
      display: block;
    }
  }

  @media (min-width: 1200px) {
    &__content {
      p {
        min-height: 50px;
      }
    }
  }
  @media (max-width: 1200px) {
    &__content {
      padding: 10px 20px 15px;
    }

    &__highlight {
      top: -2px !important;
    }

    &--isFavourite {
      padding-top: 45px;
    }

    &__price {
      font-size: 40px;
    }
    .unit {
      font-size: 15px;
    }
    .currency {
      font-size: 15px;
    }
  }
}

</style>