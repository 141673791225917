<script setup lang="ts">

import {CModal, CModalBody, CContainer} from "@coreui/vue";
import {useRoute, useRouter} from "vue-router";
import StateMessage from "@/components/Atoms/Message/StateMessage.vue";

const visibleModal = defineModel();

const router = useRouter();
const route = useRoute();
const { label, state } = defineProps<{
  label: string,
  state: string,
}>();

const onCloseModal = () => {
  visibleModal.value = false

  router.push({ name: route.name, query: {}})
}

</script>

<template>
  <CModal class="state-modal state-modal--status"
          alignment="center"
          :visible="visibleModal"
          @close="onCloseModal">
    <CModalBody>
      <CContainer class="d-flex flex-column align-items-center">
        <StateMessage :state="state" :message="label"/>

        <CButton color="primary"
                 style="min-width: 100px;"
                 @click="visibleModal = false">{{ $t('ui.ok') }}</CButton>

      </CContainer>
    </CModalBody>
  </CModal>
</template>

<style lang="scss">
.state-modal {
  .price {
    &-value {
      font-size: 30px;
    }
  }
  &--status {
    .modal-content {
      padding: 30px 0 20px;
    }
  }
}
</style>