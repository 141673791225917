<script setup lang="ts">
  import {ITemplateInput} from "@/class/TemplateInput.ts";
  import {useFormatter} from "@/composables/useFormatter.ts";

  const { value } = defineProps<{
    value: ITemplateInput
  }>();

  const { marked } = useFormatter();

</script>

<template>
  <div class="content-output">
    <p v-html="marked.parse(value?.value || '')"></p>
  </div>
</template>

<style scoped lang="scss">

</style>