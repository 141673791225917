<script setup lang="ts">

import StateMessage from "@/components/Atoms/Message/StateMessage.vue";
import {CCard, CCardBody} from "@coreui/vue";
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";
import {useI18n} from "vue-i18n";
import {useGtag} from "vue-gtag-next";

const type = ref('');
const email = ref('');

onMounted(() => {
  const {query} = useRoute();

  type.value = query.type as string;
  email.value = query.email as string;
})

const {t} = useI18n();
const {event} = useGtag()

const message = computed(() => {
  switch (type.value) {
    case 'signIn':
      return t('auth.signIn.emailSent', { email: email.value })

    case 'signUp':
      event('user_registration_email_send', {
        'event_category': 'registration',
      })

      return t('auth.signUp.emailSent', { email: email.value })
    case 'default':
      return ''
  }
})

</script>

<template>

  <section class="auth-email d-flex align-items-center justify-content-center">
    <CCard class="auth-email__card">
      <CCardBody class="d-flex align-items-center justify-content-center">
        <StateMessage :message="message" state="success"/>
      </CCardBody>
    </CCard>
  </section>
</template>

<style lang="scss">

.auth-email {
  background: var(--cui-primary);
  border: none;
  height: 100vh;
  width: 100%;

  &__card {
    min-height: 300px;
  }
}
</style>
