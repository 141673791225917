<script setup lang="ts">

const { value } = defineProps<{
  value: number
}>()

const browserLocale = navigator.language || navigator.userLanguage;

</script>

<template>
  <num #="{ number }"
       :locale="browserLocale"
       :value="value">{{ number }}</num>
</template>

<style scoped lang="scss">

</style>