import {reactive} from "vue";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";
import {getHttpAIStream} from "@/services/http.service.ts";
import {useTemplateStore} from "@/store/template.ts";
import {storeToRefs} from "pinia";
import {supabase} from "@/supabase.ts";
import {ITemplateFieldValue} from "@/class/TemplateInputFieldValue.ts";
import {TemplateInput} from "@/class/TemplateInput.ts";

const generatedTemplateData = reactive({
    error: '',
});

export function useTemplateGenerate() {
    const templateStore = useTemplateStore();
    const {
        generatingInProgress,
        templateList,
        templateInputList,
    } = storeToRefs(templateStore);

    const {t} = useI18n();

    const stopGenerating = (templateId: string) => {
        generatingInProgress[templateId].content = false;
    }

    const generate = async (templateId: number, values: ITemplateFieldValue[], onInputIdReady) => {
        const templateKey = templateList.value.find(template => template.id === templateId).key;
        const fields = Object.values(values.value)
            .filter((templateFieldValue) => templateFieldValue.value)
            .map((templateFieldValue) => ({
                id: templateFieldValue.id,
                name: templateFieldValue.name,
                value: templateFieldValue.value,
            }))

        const {data, error: insertTemplateInputError} = await supabase
            .rpc('create_template_input', {
                _name: values.value.name.value,
                _template_id: templateId,
                _fields: fields,
            })
            .single();

        const templateInputId = data.template_input_id;

        onInputIdReady({inputId: templateInputId})

        generatingInProgress[templateInputId] = {
            content: true,
        }

        templateInputList.value[templateInputId] = new TemplateInput({
            id: templateInputId,
            key: templateKey,
            name: values.value.name.value,
            value: '',
            createdAt: ''
        });

        const onInit = () => {

        };

        const onIteration = (chunk: string) => {
            if (chunk) {
                templateInputList.value[templateInputId].value += chunk;
            }

            return generatingInProgress[templateInputId].content
        };

        const onFinished = async () => {
            generatingInProgress[templateInputId].content = false;
        }

        const onError = (e) => {
            toast.error(t(`error.${e.code}`));

            generatedTemplateData.error = e.code;
            generatingInProgress[templateInputId].content = false;
        }

        return getHttpAIStream('template',
            {
                postParameters: {
                    template_input_id: templateInputId,
                    template_key: templateKey,
                    fields: fields.reduce((acc, curr) => {
                        acc[curr.name] = curr.value;

                        return acc;
                    }, {})
                }
            },
            onInit,
            onIteration,
            onFinished,
            onError
        );
    }


    return {
        generatingInProgress,
        generatedTemplateData,
        generate,
        stopGenerating,
    }
}
