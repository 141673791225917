<script setup lang="ts">
import {computed} from "vue";

const {message, state} = defineProps<{
  message: string,
  state: string,
}>();

const statusIcon = computed(() => {
  return {
    success: 'cilCheckAlt',
    fail: 'cilBan'
  }[state]
});
</script>

<template>
  <div class="state-message d-flex flex-column align-items-center">
    <CIcon class="state-message__icon"
           :class="{
              [state]: true,
           }"
           :icon="statusIcon" size="lg"/>
    <p class="text-center" v-html="message"></p>
  </div>
</template>

<style scoped lang="scss">
.state-message {
  &__icon {
    $size: 80px;
    width: $size !important;
    height: $size !important;
    margin-bottom: 10px;
    color: white;
    border-radius: 50%;
    padding: 10px;

    &.success {
      background: #23a16e;
      border: 15px solid #e5f4ed;
    }

    &.fail {
      background: #de212b;
      border: 15px solid #fdeaea;
    }
  }
}
</style>