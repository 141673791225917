<script setup lang="ts">

import {CModal, CModalBody, CContainer, CButton} from "@coreui/vue";
import {useRoute, useRouter} from "vue-router";
import StateMessage from "@/components/Atoms/Message/StateMessage.vue";

const visibleModal = defineModel();

const onCloseModal = () => {
  visibleModal.value = false
}

const { onConfirm, confirmText, closeable = true } = defineProps<{
  header: string,
  text: string,
  onConfirm: Function,
  confirmText: string,
  closeable?: boolean
}>();


const handleConfirm = () => {
  onConfirm();
  onCloseModal();
}

</script>

<template>
  <CModal alignment="center"
          :visible="visibleModal"
          size="lg"
          @close="onCloseModal">
    <CModalBody>
      <CContainer class="p-0 confirm-modal__content d-flex flex-column">
        <h4>{{ header }}</h4>
        <p>{{ text }}</p>
        <section class="mt-auto">
          <CButton class="mx-2" color="primary" @click="handleConfirm">{{confirmText}}</CButton>
          <CButton v-if="closeable" color="secondary" @click="onCloseModal">{{$t('ui.modal.cancel')}}</CButton>
        </section>
      </CContainer>
    </CModalBody>
  </CModal>
</template>

<style lang="scss">
.confirm-modal__content {
  color: black;
  min-height: 140px;
}
</style>