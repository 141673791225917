import Shepherd from "shepherd.js";
import "shepherd.js/dist/css/shepherd.css"
import {i18n} from "@/i18n/index.ts";
import {getCookie, setCookie} from "@/util/cookie.ts";
import Tour = Shepherd.Tour;

const TOUR_COOKIE_NAME = 'tour';
const TOUR_FINISHED_VALUE = '1';

export enum TourName {
    templates= 'templates',
    feedback= 'feedback',
    textContent= 'textContent',
    graphicsContent= 'graphicsContent'
}

export const useTour = () => {
    const {t} = i18n.global;

    const finishTourByName = (tourName: TourName) => {
        console.log('finishing tour', tourName);
        setCookie(`${TOUR_COOKIE_NAME}_${tourName}`, TOUR_FINISHED_VALUE, 9999 );
    }

    const startTourByName = (tourName: TourName) => {
        const isFinished = getCookie(`${TOUR_COOKIE_NAME}_${tourName}`) === TOUR_FINISHED_VALUE;

        if (!isFinished) {
            tourList[tourName].start();
        }
    }

    const createNewTour = () => new Shepherd.Tour({
        useModalOverlay: true,
        defaultStepOptions: {
            cancelIcon: {
                enabled: true
            },
            modalOverlayOpeningRadius: 20,
            modalOverlayOpeningPadding: 10,
            scrollTo: {behavior: 'smooth', block: 'center'}
        }
    });

    const tourList: {[key in TourName]: Tour } = {
        [TourName.templates]: createNewTour(),
        [TourName.textContent]: createNewTour(),
        [TourName.feedback]: createNewTour(),
        [TourName.graphicsContent]: createNewTour(),
    }

    // Finish tour on Cancel and on Complete
    Object.entries(tourList).forEach(([tourName, tour]: [TourName, Tour]) => {
        ['complete', 'cancel'].forEach(event => tour.on(event, () => {
            finishTourByName(tourName);
        }));
    })

    const buttonBack = {
        action() {
            return this.back();
        },
        classes: 'shepherd-button-secondary',
        text: t('tour.button.back')
    };

    const buttonNext = {
        action() {
            return this.next();
        },
        classes: 'btn btn-primary',
        text: t('tour.button.next')
    }

    // const buttonFinished = {
    //     action() {
    //         return this.next();
    //     },
    //     classes: 'btn btn-primary',
    //     text: t('tour.button.finished')
    // }

    tourList.templates.addStep({
        title: t('tour.introduction.header'),
        text: t('tour.introduction.text'),
        buttons: [
            buttonBack,
            buttonNext,
        ],
        id: 'templates'
    });

    tourList.templates.addStep({
        title: t('tour.templates.graphics.header'),
        text: t('tour.templates.graphics.text'),
        attachTo: {
            element: '#tour-category-graphics',
            on: 'bottom',
        },
        buttons: [
            buttonBack,
            buttonNext,
        ],
        id: 'templates-image'
    });

    tourList.templates.addStep({
        title: t('tour.templates.text.header'),
        text: t('tour.templates.text.text'),
        attachTo: {
            element: '#tour-category-text',
            on: 'top',
        },
        buttons: [
            buttonBack,
            buttonNext,
        ],
        id: 'templates-text'
    });

    tourList.templates.addStep({
        title: t('tour.templates.choose.header'),
        text: t('tour.templates.choose.text'),
        when: {
            complete() {
                finishTourByName(TourName.templates);
            }
        },
        advanceOn: {
            selector: '#template-idea', event: 'click'
        },
        attachTo: {
            element: '#template-idea',
            on: 'top',
        },
        buttons: [
            buttonBack,
        ],
        id: 'content-idea'
    });

    // Text tour
    tourList.textContent.addStep({
        title: t('tour.content_text.prompt.header'),
        text: t('tour.content_text.prompt.text'),
        attachTo: {
            element: '#tour-content-text-prompt',
            on: 'top',
        },
        buttons: [
            buttonNext,
        ],
        id: 'content-idea'
    })

    tourList.textContent.addStep({
        title: t('tour.content_text.suggestions.header'),
        text: t('tour.content_text.suggestions.text'),
        attachTo: {
            element: '#tour-content-text-suggestions',
            on: 'top',
        },
        buttons: [
            buttonBack,
            buttonNext,
        ],
        floatingUIOptions: {
            marginTop: -10,
        },
        id: 'content-idea'
    })

    tourList.textContent.addStep({
        title: t('tour.content_text.suggestion_prompt.header'),
        text: t('tour.content_text.suggestion_prompt.text'),
        attachTo: {
            element: '#tour-suggestion-prompt-0',
            on: 'top',
        },
        advanceOn: {
            selector: '#tour-suggestion-prompt-0', event: 'click',
        },
        when: {
            complete() {
                console.log('COMPLETED');
                finishTourByName(TourName.textContent);
            },
        },
        buttons: [
            buttonBack,
        ],
        id: 'content-idea'
    })

    tourList.textContent.addStep({
        title: t('tour.content_text.submit.header'),
        text: t('tour.content_text.submit.text'),
        attachTo: {
            element: '#chat-prompt-submit',
            on: 'top',
        },
        advanceOn: {
            selector: '#chat-prompt-submit', event: 'click',
        },
        when: {
            hide: function() {
                console.log('----------HIDE------------------');
                finishTourByName(TourName.textContent);
            },
        },
        buttons: [
            buttonBack,
        ],
        id: 'content-idea'
    });


    // Feedback

    tourList.feedback.addStep({
        title: t('tour.feedback.explanation.header'),
        text: t('tour.feedback.explanation.text'),
        attachTo: {
            element: '.content-item--last .tour-feedback-icons',
            on: 'left',
        },
        advanceOn: {
            selector: '.content-item--last .tour-feedback-icons', event: 'click',
        },
        id: 'content-idea'
    });

    return {
        startTourByName,
        tourList
    }
}