export interface ITemplateInputField {
    id: number;
    name: string;
    required: boolean;
    label: string;
    placeholder: string;
    type: string;
    rows?: number;
    cols?: number;
    options?: string[];
}

export class TemplateInputField implements ITemplateInputField {
    id: number;
    name: string;
    required: boolean;
    order: number;
    label: string;
    placeholder: string;
    type: string;
    rows?: number;
    cols?: number;
    options?: string[];

    constructor(params: {
        id: number;
        name: string;
        required: boolean;
        order: number;
        label: string;
        placeholder: string;
        type: string;
        rows?: number;
        cols?: number;
        options?: string[];
    }) {
        this.id = params.id;
        this.name = params.name;
        this.label = params.label;
        this.order = params.order;
        this.required = params.required;
        this.placeholder = params.placeholder;
        this.type = params.type;
        this.rows = params.rows;
        this.cols = params.cols;
        this.options = params.options;
    }

    static fromDbResponse(dbResponse: any): TemplateInputField[] {
        return dbResponse.template_input_field_map.map((item) => {
            console.log(item);
            return new TemplateInputField({
                id: item.template_input_field.id,
                name: item.template_input_field.name,
                required: item.required,
                order: item.order,
                type: item.template_input_field.template_input_field_type.name,
                options: item.template_input_field.template_input_field_option?.map((option) => option.name),
                label: "",
                placeholder: "",
            });
        })
    };
}