import './scss/tailwind.css'
import './scss/custom.scss'
import './scss/shepherd.scss'
import './scss/breakpoints.scss'
import {createApp} from 'vue'
import {createPinia, storeToRefs} from 'pinia'
import App from "./App.vue";
import 'vue3-toastify/dist/index.css';
import Vue3Toastify, {ToastContainerOptions} from 'vue3-toastify';
import VNum from "v3-num";
import Slider from '@vueform/slider'
import '@vueform/slider/themes/default.css';
import * as Sentry from "@sentry/vue";
import VueGtag from "vue-gtag-next";
import VueHotjar from 'vue-hotjar-next'

import {
    cibTiktok, cilX, cibInstagram, cibFacebookF, cilEnvelopeOpen,
    cilChatBubble, cilCopy, cilArrowBottom, cilCheckCircle,
    cilThumbUp, cilThumbDown,
    cilArrowRight, cilArrowTop, cilExcerpt, cilBasket,
    cilBell, cilCalculator, cilCalendar, cilCloudDownload, cilApplications,
    cilHistory, cilTrash, cilBan, cilSettings,
    cilAccountLogout, cilArrowCircleLeft, cilCheckAlt, cilSend,
    cilHamburgerMenu, cilBarChart, cilPlus
} from '@coreui/icons'
import router from "@/router";
import {
    CAvatar, CImage, CButton, CCard, CCardBody, CCardFooter,
    CCardGroup, CCardHeader, CCardImage,
    CCardText, CCardTitle, CCol, CAlert,
    CContainer, CForm, CFormCheck, CFormInput,
    CFormLabel, CFormRange, CFormText, CFormTextarea,
    CHeader, CHeaderNav, CInputGroup, CFormSelect,
    CNav, CNavGroup, CNavItem, CNavLink,
    CRow, CTabContent, CTabPane, CWidgetStatsB,
    CBadge, CFormSwitch, CTable, CTableHead, CTableHeaderCell, CTableBody, CTableDataCell, CTableRow, CPlaceholder,
} from "@coreui/vue";
import {CIcon} from "@coreui/icons-vue";
import {i18n} from "@/i18n/index.ts";
import {clickOutSide as vClickOutSide} from '@mahdikhashan/vue3-click-outside'
import {registerHttpInterceptor} from "@/services/interceptor.ts";
import {GOOGLE_ANALYTICS_CODE, GOOGLE_TAG_MANAGER_CODE} from "@/constants/variables.ts";
import {useUIStore} from "@/store/ui.ts";
import {createGtm} from "@gtm-support/vue-gtm";

const icons = {
    cibTiktok, cilX, cibInstagram, cibFacebookF,
    cilEnvelopeOpen, cilChatBubble, cilCheckCircle, cilCopy,
    cilThumbUp, cilThumbDown,
    cilPlus, cilApplications, cilArrowBottom, cilArrowCircleLeft,
    cilArrowRight, cilSend, cilArrowTop, cilBan,
    cilBasket, cilBell, cilTrash, cilCalculator, cilCloudDownload,
    cilCalendar, cilBarChart, cilHistory, cilSettings,
    cilAccountLogout, cilCheckAlt, cilExcerpt, cilHamburgerMenu,
}

const pinia = createPinia()

// @ts-ignore
const app = createApp(App);
app.directive('click-out-side', vClickOutSide)

app.use(pinia)
const {installedVersion} = storeToRefs(useUIStore());

// Sentry.init({
//     app,
//     release: "bluestream.ai@" + installedVersion.value,
//     dsn: "https://edc19c8fd31d492897afe2990c793a11@o4505551866232832.ingest.sentry.io/4505551867281408",
//     integrations: [
//         new Sentry.BrowserTracing({
//             // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//             tracePropagationTargets: ["https://app.bluestream.ai/"],
//             routingInstrumentation: Sentry.vueRouterInstrumentation(router),
//         }),
//         new Sentry.Replay(),
//     ],
//     // Performance Monitoring
//     tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

app.use(Vue3Toastify, {
    autoClose: 3000,
} as ToastContainerOptions);

app.use(
    createGtm({
        id: GOOGLE_TAG_MANAGER_CODE, // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
        // queryParams: {
        //     // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
        //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
        //     gtm_preview: 'env-4',
        //     gtm_cookies_win: 'x',
        // },
        defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
        compatibility: false, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
        // nonce: '2726c7f26c', // Will add `nonce` to the script tag
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        // vueRouter: router, // Pass the router instance to automatically sync with router (optional)
        // ignoredViews: ['homepage'], // Don't trigger events for specified router names (optional)
        trackOnNextTick: false, // Whether or not call trackView in Vue.nextTick
    }),
);

app.use(VueGtag, {
    useDebugger: true,
    property: {
        id: GOOGLE_ANALYTICS_CODE,
        params: {
            send_page_view: false,
        }
    }
})

app.use(VueHotjar, {
    id: 3612932,
    isProduction: true,
    snippetVersion: 6
});


registerHttpInterceptor()

app.use(i18n);

app.provide('icons', icons);

app.component('num', VNum);
app.component('Slider', Slider);
app.component('CImage', CImage)
app.component('CPlaceholder', CPlaceholder)
app.component('CTableHead', CTableHead)
app.component('CTableRow', CTableRow)
app.component('CBadge', CBadge)
app.component('CTableHeaderCell', CTableHeaderCell)
app.component('CTableBody', CTableBody)
app.component('CTableDataCell', CTableDataCell)
app.component('CTable', CTable)
app.component('CFormSwitch', CFormSwitch)
app.component('CWidgetStatsB', CWidgetStatsB)
app.component('CAvatar', CAvatar)
app.component('CHeader', CHeader)
app.component('CIcon', CIcon)
app.component('CRow', CRow)
app.component('CCol', CCol)
app.component('CContainer', CContainer)
app.component('CCard', CCard)
app.component('CButton', CButton)
app.component('CCardText', CCardText)
app.component('CCardTitle', CCardTitle)
app.component('CCardBody', CCardBody)
app.component('CCardImage', CCardImage)
app.component('CCardHeader', CCardHeader)
app.component('CCardFooter', CCardFooter)
app.component('CCardGroup', CCardGroup)
app.component('CFormTextarea', CFormTextarea)
app.component('CForm', CForm)
app.component('CFormInput', CFormInput)
app.component('CFormCheck', CFormCheck)
app.component('CFormLabel', CFormLabel)
app.component('CFormText', CFormText)
app.component('CFormSelect', CFormSelect)
app.component('CInputGroup', CInputGroup)
app.component('CHeaderNav', CHeaderNav)
app.component('CNavItem', CNavItem)
app.component('CNav', CNav)
app.component('CNavGroup', CNavGroup)
app.component('CNavLink', CNavLink)
app.component('CTabContent', CTabContent)
app.component('CTabPane', CTabPane)
app.component('CFormRange', CFormRange)
app.component('CAlert', CAlert)

app.use(router)

// for some reason Supabase does not make requests/is not ready when app is mounted so this postpone is a HACK
setTimeout(() => {
app.mount('#app')
    // console.log('app mounted')
}, 200);
