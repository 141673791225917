<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/user.ts";
import {onBeforeMount, watch} from "vue";
import {addEmailSubscriber, updateGATrackingId} from "@/services/http.service.ts";
import {getCookie} from "@/util/cookie.ts";
import {useRouter} from "vue-router";
import {validateJSONString} from "@/util/index.ts";

const {user} = storeToRefs(useUserStore());

onBeforeMount(() => {
  const utmCookies = getCookie('utm_parameters');
  const jsonString = decodeURIComponent(utmCookies);
  const isValidJSONString = validateJSONString(jsonString);

  if (!isValidJSONString) return;

  const router = useRouter();

  router.replace({
    query: {
      ...JSON.parse(jsonString)
    }
  })
})

watch(user, async () => {
  // Set tracking GA id only if it is not set already
  if (Object.keys(user.value).length !== 0 && !user.value.ga_id) {
    updateGATrackingId();

    const [firstname, lastname] = user.value?.full_name?.split(' ');

    if (firstname && lastname) {
      await addEmailSubscriber({
        name: firstname,
        surname: lastname,
        email: user.value.email,
      });
    }
  }
}, {
  immediate: true
})

</script>

<template>
  <CCard class="p-3  mt-3">
    <CCardBody class="d-flex flex-column">
      <h1>{{ $t('intro.welcome.hello') }} {{ user?.full_name }}!</h1>
      <div class="mt-5" v-html="$t('intro.welcome.html')"/>
      <CButton color="primary"
               class="align-self-center mt-5"
               @click="$router.push({ name: 'introSubscription' })">{{ $t('intro.button.chooseSubscription') }}</CButton>
    </CCardBody>
  </CCard>
</template>

<style scoped lang="scss">

</style>