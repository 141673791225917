<script setup lang="ts">
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/user.ts";
import {computed, ref} from "vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useI18n} from "vue-i18n";
import {useRouter} from "vue-router";
import {CCol, CContainer, CPlaceholder, CRow} from "@coreui/vue";
import ProductCard from "@/components/Intro/ProductCard.vue";

const {t} = useI18n();

const router = useRouter();
const {user} = storeToRefs(useUserStore());
const selectedSubscriptionIndex = ref(0);
const {productByRecurring} = storeToRefs(useSubscriptionStore());

const productList = computed(() => {
  return productByRecurring.value['month']
      .sort((a, b) => {
        return parseInt(a.metadata.order) - parseInt(b.metadata.order);
      })
      .filter((product) => product.active)
});

const emit = defineEmits(['submit']);

const submit = (priceId) => {
  emit('submit', priceId);
}

</script>

<template>
  <CForm v-if="productList.length" class="row d-flex flex-column subscription-content">
    <CContainer class="mb-4">
      <CRow class="flex-column flex-xl-row">
        <CCol
            class="subscription-content__card mb-4 p-0 p-md-2"
            v-for="(product, index) in productList">
          <ProductCard
              @submit="submit(product.priceId)"
              :product="product"
              :isFavourite="index === 2" />
        </CCol>
      </CRow>
    </CContainer>

  </CForm>
  <CPlaceholder v-else component="p" animation="glow">
    <CPlaceholder color="secondary" :xs="12" size="lg"/>
    <CPlaceholder color="secondary" :xs="12" size="m"/>
  </CPlaceholder>
</template>

<style scoped lang="scss">
.subscription-content {
  h3 {
    color: black;
  }

  .icon {
    border-radius: 50%;
    background: var(--cui-primary);
    padding: 3px;
    color: white;

    &.red {
      background: red;
    }
  }
}

</style>