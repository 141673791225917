<script setup lang="ts">
import {useRouter} from "vue-router";
import {ref} from 'vue'
import Divider from "@/components/Divider.vue";
import {supabase} from "@/supabase.ts";
import {useI18n} from "vue-i18n";
import {toast} from "vue3-toastify";
import {useGtag} from "vue-gtag-next";
import Auth from "@/views/Auth.vue";

const email = ref('');
const errorEmail = ref('');
const errorGoogle = ref('');

const {t} = useI18n();
const {event} = useGtag();

const router = useRouter();
const signIn = async () => {
  const {data, error} = await supabase.auth.signInWithOtp({
    options: {
      shouldCreateUser: false,
    },
    email: email.value,
  });

  if (!error) {
    event("login", {
      method: "magic_link"
    });

    await router.push({
      name: 'emailConfirm',
      query: {
        type: 'signIn',
        email: email.value
      }
    })

  } else {
    errorEmail.value = t(`error.signin.${error.status}`);
    toast.error(t(`error.signin.${error.status}`));
  }
}

const signInWithGoogle = async () => {
  const {data, error} = await supabase.auth.signInWithOAuth({
    provider: 'google',
    options: {
      redirectTo: '/dashboard'
    },
  });

  if (!error) {
    event("login", {
      method: "google"
    });

    router.push('/dashboard');
  } else {
    errorEmail.value = error.message;
    console.error(error);
  }
}

</script>

<template>
  <Auth
      :header="$t('auth.signIn.header')"
      :subheader="$t('auth.signIn.subheader')"
  >
    <CForm @submit.prevent="signIn" class="d-flex flex-column">
      <CFormInput
          v-model="email"
          class="mb-2"
          type="email"
          id="signinEmail"
          :label="$t('user.email')"
          placeholder="jiri.novak@seznam.cz"
          aria-describedby="exampleFormControlInputHelpInline"
      />

      <CAlert class="w-100" v-if="errorEmail.length > 0" color="danger">{{ errorEmail }}</CAlert>
      <CButton class="mt-3 w-100" size="lg" color="primary" type="submit">{{ t('auth.signIn.login') }}</CButton>
    </CForm>

    <CFormText class="my-4 text-center">{{ t('auth.signIn.no_account') }}
      <router-link to="/sign-up">{{ t('auth.signIn.create') }}</router-link>
    </CFormText>

    <Divider class="mb-4 mt-5" :text="$t('ux.or')"/>

    <CAlert class="w-100" v-if="errorGoogle.length > 0" color="danger">{{ errorGoogle }}</CAlert>

    <div class="d-flex justify-content-center">
      <button @click="signInWithGoogle"
              class="mb-2 sign-up__oauth" size="lg"
      >
        <img src="/public/icons/google.png" alt="google"/>
      </button>
    </div>
  </Auth>
</template>

<style lang="scss">

</style>
