<script setup lang="ts">
import {ITemplate} from "@/model/index.ts";
import LoadingButton from "@/components/Atoms/LoadingButton/LoadingButton.vue";
import {computed, ref, watch} from "vue";
import {ITemplateInputField} from "@/class/TemplateInputField.ts";
import {ITemplateFieldValue} from "@/class/TemplateInputFieldValue.ts";
import {useI18n} from "vue-i18n";

const emit = defineEmits(['submit']);

const handleSubmit = () => {
  if (!isFormValid.value) return;

  emit('submit', formValues);
}

const isFormValid = computed(() => !Object.values(formValues.value).some((field) => {
  return field.required && !field.value;
}));

const { inputs, template, values } = defineProps<{
  inputs: ITemplateInputField[];
  values: ITemplateFieldValue[];
  template: ITemplate
}>();

const formValues = ref([]);

const updateFormValues = () => {
  formValues.value = inputs.reduce((acc, field) => {
    const existingValue = values.find((value)=> {
      return value.fieldName === field.name
    })?.value;

    acc[field.name] = {
      value: existingValue || '',
      required: field.required,
      id: field.id,
      name: field.name,
    };
    return acc;
  }, {})
};

watch(() => [inputs, values], () => {
  updateFormValues();
})

const i18n = useI18n();
const { t } = i18n;

const getItemLabel = (item: ITemplateInputField, templateKey: string) => {
  if (!item || !templateKey) return '';

  return `${item.required ? '*' : ''} ${t(`${templateKey}.${item.name}`)}`;
}

</script>

<template>
  <div class="content-input">
    <CForm @submit.prevent="handleSubmit" v-model="formValues" class="text-center">
      <h2>{{$t(`template.${template?.key}`)}}</h2>
      <p>{{$t(`template.${template?.key}.description`)}}</p>

      <CCol class="mt-5">
        <CCol
            v-for="item in inputs"
            :key="item.id"
            :cols="12"
            class="mb-4 text-start"
        >
          <CFormInput
              :id="item.id"
              :label="getItemLabel(item, template?.key)"
              :placeholder="item.placeholder"
              :type="item.type"
              v-model="formValues[item.name].value"
              v-if="item.type === 'input'"
          />
          <CFormTextarea
              :id="item.id"
              :label="getItemLabel(item, template?.key)"
              :placeholder="item.placeholder"
              :rows="item.rows"
              :cols="item.cols"
              v-model="formValues[item.name].value"
              v-if="item.type === 'textarea'"
          />
          <CFormSelect
              :id="item.id"
              :label="getItemLabel(item, template?.key)"
              :placeholder="item.placeholder"
              v-model="formValues[item.name].value"
              v-if="item.type === 'select'"
          >
            <option
                v-for="option in item.options"
                :key="option"
                :value="option"
            >
                {{ option }}
            </option>
          </CFormSelect>
        </CCol>
      </CCol>
      <LoadingButton
          :loading="false"
          :disabled="!isFormValid"
          color="primary"
          type="submit">Submit</LoadingButton>
    </CForm>
  </div>
</template>

<style scoped lang="scss">

</style>