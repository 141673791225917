import {defineStore} from "pinia";
import {supabase} from "@/supabase.ts";
import {reactive, ref} from "vue";
import {ITemplateInputField, TemplateInputField} from "@/class/TemplateInputField.ts";
import {ITemplateFieldValue, TemplateInputFieldValue} from "@/class/TemplateInputFieldValue.ts";
import {ITemplateInput, TemplateInput} from "@/class/TemplateInput.ts";

interface ITemplateGeneratingProgress {
    content: boolean;
}

export const useTemplateStore = defineStore('template', () => {
    const generatingInProgress = reactive<Record<string, ITemplateGeneratingProgress>>({});

    const templateList = ref<Record<string, any>>([]);
    const templateInputList = ref<Record<string, ITemplateInput>>();
    const templateCategoryList = ref<Record<string, any>>([]);

    const getTemplateList = async () => {
        const response = await supabase
            .from('template')
            .select('id,key,category:template_category(id,name)')
            .order('order', {ascending: true});

        console.log('response', response);
        templateList.value = response.data;
    }

    const getTemplateCategoryList = async () => {
        const response = await supabase
            .from('template_category')
            .select('id,name')
            .order('order');

        templateCategoryList.value = response.data;
    }

    const getTemplateInputValues = async (inputId: string): Promise<ITemplateFieldValue[]> => {
        const response = await supabase
            .from('template_input_value')
            .select(`
                template_input(id),
                template_input_field(id,name),
                value
            `)
            .eq(' template_input_id', inputId)

        if (response.error) {
            return [{}] as ITemplateFieldValue[];
        }

        return response.data.map((item) => TemplateInputFieldValue.fromDbResponse(item)) || [];
    }

    const getTemplateInputList = async (): Promise<any[]> => {
        const response = await supabase
            .from('template_input')
            .select(`
                id,
                name,
                response,
                created_at,
                template(key)
            `)
            .order('created_at', {ascending: false});

        if (response.error) {
            return [{}] as ITemplateInputField[];
        }

        templateInputList.value = response.data.reduce((acc, curr) => {
            acc[curr.id] = TemplateInput.fromDbResponse(curr);

            return acc;
        }, {});
    }

    const getTemplateInputFields = async (templateKey: string): Promise<ITemplateInputField[]> => {
        const response = await supabase
            .from('template')
            .select(`
                id,
                key,
                template_input_field_map(id, required, order, template_input_field(id,name,template_input_field_type(name),
                template_input_field_option(name)))
            `)
            .eq('key', templateKey)
            .single();

        if (response.error) {
            return [{}] as ITemplateInputField[];
        }

        return TemplateInputField.fromDbResponse(response.data)
            .sort((a,b) => a.order - b.order);
    }

    const deleteTemplateInputById = async (templateId: number) => {
        await supabase
            .from('template_input')
            .delete()
            .eq('id', templateId);

        await getTemplateInputList();
    }

    return {
        templateList,
        templateInputList,
        templateCategoryList,
        generatingInProgress,

        getTemplateList,
        getTemplateInputList,
        getTemplateCategoryList,
        getTemplateInputFields,
        getTemplateInputValues,
        deleteTemplateInputById,
    }
})
