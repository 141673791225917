import {ITemplateInput} from "@/class/TemplateInput.ts";

export enum ESideMenuType {
    CHAT = 'chat',
    TEMPLATE = 'template',
}
export interface ISideMenuItem {
    id: number;
    key: string;
    name: string;
    type: ESideMenuType;
}

export class SideMenuItem implements ISideMenuItem {
    id: number;
    key: string;
    name: string;
    type: ESideMenuType;

    constructor(params: {
        id: number;
        key: string;
        name: string;
        type: ESideMenuType;
    }) {
        this.id = params.id;
        this.key = params.key;
        this.name = params.name;
        this.type = params.type;
    }

    static fromDbResponse(item: any, type: ESideMenuType): SideMenuItem {
        return new SideMenuItem({
            id: item.id,
            key: type === ESideMenuType.TEMPLATE ? item?.template?.key : ESideMenuType.CHAT,
            name: item.name,
            type,
        });
    };

    static fromTemplateInput(item: ITemplateInput): SideMenuItem {
        return new SideMenuItem({
            id: item.id,
            key: item.key,
            name: item.name,
            type: ESideMenuType.TEMPLATE,
        });
    };
}