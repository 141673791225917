// @ts-nocheck
import {createRouter, createWebHistory, RouteLocationNormalized} from "vue-router";
import TemplateList from "@/components/TemplateList.vue";
import Chat from "@/components/templates/Chat/Chat.vue";
import SignIn from "@/views/SignIn.vue";
import SignUp from "@/views/SignUp.vue";
import Main from "@/views/Main.vue";
import {supabase} from "@/supabase.ts";
import Settings from "@/components/Settings/Settings.vue";
import SettingsProfile from "@/components/Settings/SettingsProfile.vue";
import SettingsTokens from "@/components/Settings/SettingsTokens.vue";
import CMSContent from "@/views/CMSContent.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import Intro from "@/views/Intro.vue";
import Welcome from "@/components/Intro/Welcome.vue";
import Subscription from "@/components/Intro/Subscription.vue";
import Invoice from "@/components/Intro/Invoice.vue";
import SettingsSubscription from "@/components/Settings/Subscription/SettingsSubscription.vue";
import SettingsSupport from "@/components/Settings/SettingsSupport.vue";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {storeToRefs} from "pinia";
import {i18n} from "@/i18n/index.ts";
import AuthEmail from "@/views/AuthEmail.vue";
import {useGtag} from "vue-gtag-next";
import Template from "@/components/templates/Template/Template.vue";

const routes = [
    {
        path: '/',
        redirect: '/templates',
        meta: {
            title: 'title.templates'
        },
    },
    {
        path: '/sign-in',
        name: 'signIn',
        component: SignIn,
        meta: {
            noAuth: true,
            title: 'title.signIn'
        },
    },
    {
        path: '/sign-up',
        name: 'signUp',
        component: SignUp,
        meta: {
            noAuth: true,
            title: 'title.signUp'
        },
    },
    {
        path: '/email-confirm',
        name: 'emailConfirm',
        component: AuthEmail,
        meta: {
            noAuth: true,
            title: 'title.emailConfirm'
        },
    },
    {
        path: '/forgot-password',
        name: 'forgotPassword',
        component: ForgotPassword,
        meta: {
            noAuth: true,
            title: 'title.forgotPassword'
        },
    },
    {
        path: '/',
        name: 'app',
        component: Main,
        meta: {
            requiresAuth: true,
            title: 'title.app'
        },
        children: [
            {
                path: '/intro',
                name: 'intro',
                component: Intro,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '/intro/welcome',
                        name: 'introWelcome',
                        component: Welcome,
                        meta: {
                            title: 'title.intro.welcome'
                        },
                    },
                    {
                        path: '/intro/subscription',
                        name: 'introSubscription',
                        component: Subscription,
                        meta: {
                            title: 'title.intro.subscription'
                        },
                    },
                    {
                        path: '/intro/invoice',
                        name: 'introInvoice',
                        component: Invoice,
                        meta: {
                            title: 'title.intro.invoice'
                        },
                    }
                ]
            },
            {
                path: '/templates',
                name: 'templates',
                component: TemplateList,
                meta: {
                    requiresAuth: true,
                    title: 'title.templates'
                },
            },
            {
                path: '/settings',
                name: 'settings',
                component: Settings,
                meta: {
                    requiresAuth: true,
                },
                children: [
                    {
                        path: '/settings/profile',
                        name: 'settingsProfile',
                        component: SettingsProfile,
                        meta: {
                            title: 'title.settings.profile'
                        },
                    },
                    {
                        path: '/settings/subscription',
                        name: 'settingsSubscription',
                        component: SettingsSubscription,
                        meta: {
                            title: 'title.settings.subscription'
                        },
                    },
                    {
                        path: '/settings/tokens',
                        name: 'settingsTokens',
                        component: SettingsTokens,
                        meta: {
                            title: 'title.settings.tokens'
                        },
                    },
                    {
                        path: '/settings/support',
                        name: 'settingsSupport',
                        component: SettingsSupport,
                        meta: {
                            title: 'title.settings.support'
                        },
                    }
                ],
            },
            {
                path: '/chat/:chatId',
                name: 'chat',
                component: Chat,
                meta: {
                    requiresAuth: true,
                    title: 'title.chat'
                },
            },
            {
                path: '/template/:templateKey/:outputId?',
                name: 'template',
                component: Template,
                meta: {
                    requiresAuth: true,
                    title: 'title.template'
                },
            },
            {
                path: '/cms/:cms',
                name: 'cms',
                component: CMSContent,
                meta: {
                    requiresAuth: true,
                    title: 'title.cms'
                },
            },
        ],
    },
];

const router = createRouter({
    history: createWebHistory(),
// @ts-ignore
    routes,
})

const userHasSubscription = async () => {
    const subscriptionStore = useSubscriptionStore();
    const {subscription} = storeToRefs(subscriptionStore)

    if (!subscription?.value || Object.keys(subscription?.value).length === 0) {
        await subscriptionStore.getSubscription();
    }

    return Object.keys(subscription?.value).length > 0;
}

const handleTitleChange = (to: RouteLocationNormalized) => {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
    const {event} = useGtag();

    if (nearestWithTitle) {
        document.title =
            `${i18n.global.t('title.base')} - ${i18n.global.t(nearestWithTitle.meta.title)}`;
    }

    event('page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: window.location.pathname,
    })
}

// @ts-ignore
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    handleTitleChange(to);

    if (to.name === from.name) return true;

    const sessionResponse = await supabase.auth.getSession();
    const user = sessionResponse?.data?.session?.user
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const noAuth = to.matched.some(record => record.meta.noAuth);
    const routeIntro = to.matched.some(record => record.name === 'intro');

    const allowedRouteWithoutSubscription = to.matched.some(record => {
        return record.name === 'introInvoice' ||
        record.name === 'settingsProfile' ||
        record.name === 'settingsSupport';
    });

    console.log('allowedRouteWithoutSubscription', allowedRouteWithoutSubscription);
    console.log(to.name);

    // Not signed in
    if (!user) {
        if (requiresAuth) {
            return '/sign-in'
        } else {
            return true;
        }
    }

    // Signed in, going to Authorization pages
    if (user && noAuth) {
        return from.name || '/';
    }

    // Signed in, No subscription -> only Intro and Profile access
    if (user && !(await userHasSubscription())) {
        if (!routeIntro && !allowedRouteWithoutSubscription) {
            return '/intro/welcome'
        }
        console.log('ALLOWED');
        return true;
        // Subscribed
    } else {
        // Trying intro page
        if (routeIntro) {
            return from.name || '/';
        }
        return true;
    }
})

export default router;

