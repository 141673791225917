<script setup lang="ts">
import SuggestionContainer from "@/components/SuggestionContainer/SuggestionContainer.vue";
import {Marked} from 'marked';
import hljs from 'highlight.js';
import {markedHighlight} from "marked-highlight";
import Spinner from "@/components/Atoms/Spinner/Spinner.vue";
import {storeToRefs} from "pinia";
import {useChatStore} from "@/store/chat.ts";
import {inject} from "vue";
import {useFormatter} from "@/composables/useFormatter.ts";

const {content, suggestions, isLast} = defineProps<{
  content: string,
  type: string,
  isLast: boolean,
  suggestions: string[]
}>();
const chatId = inject('chatId');

const {generatingInProgress} = storeToRefs(useChatStore());

const { marked } = useFormatter();

</script>

<template>
  <div class="text-item d-flex flex-column w-100">
    <div class="d-flex flex-row">
      <p v-if="content" class="w-100" v-html="marked.parse(content)"/>
      <Spinner v-else
               class="mx-3"
               size="sm"
               color="primary"/>
    </div>

    <section class="d-flex align-items-center flex-column mt-3">
      <template
          v-if="generatingInProgress?.[chatId]?.suggestions && isLast">
        <span class="font-size--12 color-primary">{{$t('suggestions.loader.text')}}</span>
        <Spinner
            class="mt-2"
            size="sm"
            color="primary"
        />
      </template>

      <SuggestionContainer
          v-else-if="suggestions?.length > 0"
          :suggestions="suggestions"
          :type="type"
      />
    </section>
  </div>
</template>

<style lang="scss">
.text-item {
  pre code {
    display: block;
    padding: 20px;
    border-radius: 10px;
  }

  p {
    margin: 0;
  }
}
</style>