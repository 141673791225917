<script setup lang="ts">
import {useSubscriptionStore} from "@/store/subscription.ts";
import {storeToRefs} from "pinia";
import {DateFormatType, dateInflections, formatDate} from "@/util/date.ts";

const subscriptionStore = useSubscriptionStore();
const { subscription, trialEndsDate, isTrial } = storeToRefs(subscriptionStore);

</script>

<template>
  <CWidgetStatsB
      class="trial-card"
      :class="{
        'trial-card--finished': !isTrial
      }"
      :progress="0"
  >
    <template #text>{{ $t('subscription.trial.cardLabel') }}</template>
    <template #value>{{$t('dashboard.subscription.trial.header')}}</template>
    <template #title>
      {{ $t('dashboard.subscription.trial.ends_on') }} {{ formatDate(trialEndsDate, DateFormatType.DATE) }}
    </template>
  </CWidgetStatsB>
</template>

<style lang="scss" scoped>
  .trial-card {
    &--finished {
      text-decoration: line-through;
      opacity: 0.8;
    }
  }
</style>