<script setup lang="ts">
import {CSidebar, CSidebarBrand, CSidebarNav,} from "@coreui/vue";

import {useRouter} from "vue-router";
import {computed, ref} from "vue";
import {CIcon} from "@coreui/icons-vue";
import {useChatStore} from "@/store/chat.ts";
import {storeToRefs} from "pinia";
import ContentTypeIcon from "@/components/Atoms/ContentTypeIcon/ContentTypeIcon.vue";
import ConfirmModal from "@/components/Atoms/ConfirmModal/ConfirmModal.vue";
import {useTemplateStore} from "@/store/template.ts";
import {ESideMenuType, ISideMenuItem, SideMenuItem} from "@/class/SideMenuItem.ts";

const sidebarVisibleModel = defineModel();
const chatStore = useChatStore();
const templateStore = useTemplateStore();

const {chatList} = storeToRefs(chatStore);
const {templateInputList} = storeToRefs(templateStore);
const router = useRouter();

const sideMenuList = computed<ISideMenuItem[]>(() => {
  return [
      ...chatList.value.map((chatItem) =>
          SideMenuItem.fromDbResponse(chatItem, ESideMenuType.CHAT)),
      ...Object.values(templateInputList.value || {})?.map((templateInputItem) =>
          SideMenuItem.fromTemplateInput(templateInputItem)),
  ]
})

const navigateTo = (path: string) => {
  router.push(path);
  if (window.innerWidth <= 769) {
    sidebarVisibleModel.value = false;
  }
}

const sideMenu = ref(null);

const handleRemove = () => {
  if (removeItemType.value === ESideMenuType.CHAT) {
    chatStore.deleteChatById(removeItemId.value);
  } else if (removeItemType.value === ESideMenuType.TEMPLATE) {
    templateStore.deleteTemplateInputById(removeItemId.value);
  }

  router.push('/templates')
}

const removeItemId = ref(null);
const removeItemType = ref(null);

const removalModalIsOpen = ref(false);

const removalModalHandler = (itemId: number, itemType: ESideMenuType) => {
  removeItemId.value = itemId;
  removeItemType.value = itemType;
  removalModalIsOpen.value = true;
}

const getSideMenuItemRoute = (sideMenuItem: SideMenuItem) => {
  return (sideMenuItem.type === ESideMenuType.TEMPLATE ?
      `/template/${sideMenuItem.key}/${sideMenuItem.id}` :
      `/chat/${sideMenuItem.id}`)
}

</script>
<template>
  <CSidebar :visible="sidebarVisibleModel"
            ref="sideMenu"
            class="side-menu"
            :class="{
              show: sidebarVisibleModel,
              hide: !sidebarVisibleModel
            }"
            position="fixed" :overlaid="false">
    <CSidebarBrand class="d-flex justify-content-center align-items-center pt-3">
      <img class="logo" alt="logo" src="/logo.png"/>
    </CSidebarBrand>
    <CSidebarNav>
      <li class="nav-title"></li>
      <li>
        <a class="nav-link pointer"
           :class="{
              active: $route.path === '/templates'
           }"
           @click="navigateTo('/templates')">
          <CIcon customClassName="nav-icon" icon="cilPlus"/>
          {{ $t('sideMenu.templates.new') }}
        </a>
      </li>

      <div class="side-menu__chat-list">
        <div class="chat-item" v-for="sideMenuItem in sideMenuList">
          <RouterLink
              :to="getSideMenuItemRoute(sideMenuItem)"
              class="max-w-full flex justify-start items-start text-sm text-decoration-none text-white px-2 py-3"
              :class="{ 'bg-white bg-opacity-10': $route.path === getSideMenuItemRoute(sideMenuItem) }">
            <ContentTypeIcon
                class="side-menu__chat-icon flex-none w-[20px] mr-2"
                :type="sideMenuItem?.key"/>
            <span class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap">
              {{ $t(sideMenuItem.name) || $t(`template.${sideMenuItem?.key}`) }}
            </span>
          </RouterLink>
          <CIcon
              class="icon-remove cursor-pointer"
              @click="removalModalHandler(sideMenuItem.id, sideMenuItem.type)"
              icon="cilTrash"/>
        </div>
      </div>

      <li class="mt-auto">
        <a class="nav-link pointer"
           :class="{
              active: !!$route.matched.find(({ name }) => name === 'settings')
           }"
           id="nav-item_settings"
           @click="navigateTo('/settings/profile')">
          <CIcon customClassName="nav-icon" icon="cilSettings"/>
          {{ $t('sideMenu.settings') }}
        </a>
      </li>
      <ConfirmModal
          v-model="removalModalIsOpen"
          :on-confirm="handleRemove"
          :header="$t('sideMenu.chat.remove.header')"
          :text="$t('sideMenu.chat.remove.text')"
          :confirm-text="$t('sideMenu.chat.remove.confirm')"/>
    </CSidebarNav>
  </CSidebar>
</template>

<style lang="scss">

.side-menu {
  max-height: 100%;

  &__token-count {
    background: var(--cui-body-color) !important;
    cursor: default;

    &:hover {
      background: var(--cui-body-color) !important;
    }
  }

  &__chat-list {
    max-height: calc(100% - 200px);
    overflow-y: auto;

    &::-webkit-scrollbar-track {
      box-shadow: none;
      background: transparent;
      border: none;
    }

    &::-webkit-scrollbar {
      width: 8px;
      background: none;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #6e758d;
      border-radius: 5px;
    }

    .nav-item {
      a {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block !important;
      }
    }
  }

  .chat-item {
    position: relative;

    &:hover {
      .icon {
        display: block;
      }
    }

    .icon {
      display: none;
      cursor: pointer;
      position: absolute !important;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .logo {
    width: 170px;
    margin-left: -45px;
  }

  .side-menu-wrapper ~ * {
    --cui-sidebar-occupy-start: 16rem !important;
  }
}

@media(max-width: 768px) {
  //.sidebar {
  //  margin-left: 0 !important;
  //}
  :root {
    .side-menu-wrapper ~ * {
      --cui-sidebar-occupy-start: 0 !important;
    }
  }
}

</style>