<script setup lang="ts">

import {computed, Ref, ref, watch} from "vue";
import {useChatGenerate} from "@/composables/useChatGenerate.ts";
import {storeToRefs} from "pinia";
import {AIContentTypes, useChatStore} from "@/store/chat.ts";
import {ChatFilterItem} from "@/components/templates/Chat/ChatFilter.vue";
import {CPlaceholder} from "@coreui/vue";
import {useI18n} from "vue-i18n";
import {useSubscriptionStore} from "@/store/subscription.ts";
import {useUIStore} from "@/store/ui.ts";
import {useSubscriptionAccess} from "@/composables/useSubscriptionUtils.ts";
import ChatPrompt from "@/components/templates/Chat/ChatPrompt/ChatPrompt.vue";
import ChatPromptNoAccess from "@/components/templates/Chat/ChatPrompt/ChatPromptNoAccess.vue";
import {useGtag} from "vue-gtag-next";
import {TourName, useTour} from "@/store/tour.ts";

const {appReady} = storeToRefs(useUIStore());
const content = ref('');
const length = ref(2);
const style = ref('');

const {chatId, chat} = defineProps<{
  chatId: string,
  chat: any,
}>();

const chatStore = useChatStore();
const {subscription, hasSubscription, isActive, isTrial, product} = storeToRefs(useSubscriptionStore());
const {hasTrialImagesLeft, hasFeatureImage, hasFeatureText} = useSubscriptionAccess();
const {filterListByContentType} = storeToRefs(chatStore);

const contentType = computed(() => {
  return chat?.value?.content_type;
})

export interface FormData {
  [key: string]: ChatFilterItem;
}

let filterData: Ref<FormData> = ref({});

const {t} = useI18n();
const {event} = useGtag();

const {generateContentByType} = useChatGenerate();

const onSubmit = async (promptText: string) => {
  const activeFilterFormData: { [key: string]: string } = Object.entries(filterData.value)
      .filter(([_, filterItem]: [string, ChatFilterItem]) => {
        return filterItem.active;
      })
      .reduce((acc, [name, filterItem]) => {
        return {
          [name]: filterItem.value,
          ...acc
        };
      }, {}) || {};

  await generateContentByType(chatId, contentType?.value, {
    promptText,
    ...activeFilterFormData,
  })

  const {startTourByName} = useTour();

  startTourByName(TourName.feedback);

  if (contentType?.value?.type === AIContentTypes.IMAGE) {
    event('generate_image', {
      'event_category': 'generate',
      'event_label': contentType?.value?.type
    })

  } else {
    event('generate_text', {
      'event_category': 'generate',
      'event_label': contentType?.value?.type
    })
  }
}

watch(() => ({...filterListByContentType.value, ...contentType.value}), () => {
  if (!filterListByContentType.value || !contentType.value) return;

  filterData.value = filterListByContentType?.value?.[contentType?.value?.type]?.map((filter) => {
    return ({
      [filter.name]: filter.default
    });
  }).reduce((acc, curr) => {
    const key = Object.keys(curr)[0];

    acc[key] = ref({
      value: curr[key],
      active: false
    });

    return acc;
  }, {}) || {};
}, {immediate: true})

</script>

<template>
  <div class="chat-prompt-container"
       :key="chatId"
  >
    <template v-if="appReady">
      <ChatPrompt
          class="chat-prompt-container__prompt"
          @submit="onSubmit"
          :filters="filterData"
          :chatId="chatId"
          :chat="chat"/>
      <div class="chat-prompt-container__fog"></div>

      <ChatPromptNoAccess
          v-if="!isTrial && !hasSubscription"
          :header="$t('ui.trial.finished.header')"
          :text="$t('ui.trial.finished.text')"
          :button-text="$t('ui.trial.finished.button')"
      >
        <img src="/public/image/sad-robot.png" height="374" width="202"  alt="robot looking sad"/>
      </ChatPromptNoAccess>
      <template v-else>
        <template v-if="contentType?.type === 'image'">
          <ChatPromptNoAccess
              v-if="hasTrialImagesLeft"
              :header="$t('ui.trial.noFreeImages.header')"
              :text="$t('ui.trial.noFreeImages.text')"
              :button-text="$t('ui.trial.noFreeImages.button')"
          />
          <ChatPromptNoAccess
              v-if="!hasFeatureImage"
              :header="$t('ui.feature.image.missing.header')"
              :text="$t('ui.feature.image.missing.text')"
              :button-text="$t('ui.feature.image.missing.button')"
          />
        </template>
        <template v-else>
          <ChatPromptNoAccess
              v-if="!hasFeatureText"
              :header="$t('ui.feature.text.missing.header')"
              :text="$t('ui.feature.text.missing.text')"
              :button-text="$t('ui.feature.text.missing.button')"
          />
        </template>
      </template>
    </template>
    <CPlaceholder
        v-else
        component="p" animation="glow">
      <CPlaceholder class="chat-prompt-container__skeleton" color="secondary" :xs="12"/>
    </CPlaceholder>
  </div>
</template>

<style lang="scss">

.chat-prompt-container {
  position: fixed;
  bottom: 0;
  $maxWidth: 900px;
  width: calc(100% - 16rem);
  max-width: $maxWidth;
  left: max(calc(16rem + calc((100% - 16rem - #{$maxWidth}) / 2)), 16rem);

  .no-access-image {
    max-height: 250px;
  }

  &__prompt {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  &__skeleton {
    height: 50px;
    margin: 20px;
    width: calc(100% - 40px) !important;
    border-radius: 25px !important;
  }

  &__fog {
    width: 100%;
    height: 50px;
    background: white;
    box-shadow: -13px 0 16px 17px white;
    position: fixed;
    bottom: 0;
    z-index: 1;
  }


  @media (max-width: 768px) {
    left: 0;
    width: 100%;
  }
}
</style>