<script setup lang="ts">
import TemplateInput from "@/components/templates/Template/TemplateInput.vue";
import TemplateOutput from "@/components/templates/Template/TemplateOutput.vue";
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import {useTemplateStore} from "@/store/template.ts";
import {storeToRefs} from "pinia";
import {useTemplateGenerate} from "@/composables/useTemplateGenerate.ts";

const router = useRouter();
const route = useRoute();
const templateKey = ref(route.params.templateKey);
const inputIdRef = ref(route.params.outputId);

const templateStore = useTemplateStore();
const { templateList, templateInputList } = storeToRefs(templateStore);
const templateGenerate = useTemplateGenerate();

const formItems = ref([]);
const inputValues = ref([]);
const template = ref(null);
const templateResponse = computed(() => {
  return templateInputList.value[inputIdRef.value as string];
});

watch(() => route.params.templateKey, (val) => {
  templateKey.value = val;
}, { immediate: true });

watch(() => route.params.outputId, (val) => {
  initializeTemplate();

  inputIdRef.value = val;
}, { immediate: true });

async function initializeTemplate() {
  formItems.value = await templateStore.getTemplateInputFields(templateKey.value as string);

  inputValues.value = await templateStore.getTemplateInputValues(inputIdRef.value as string);

  template.value = templateList.value.find(template => template?.key === templateKey.value);
}

onMounted(async () => {
  initializeTemplate();
})

watch(() => inputIdRef, (val) => {
  console.log('outputId outputId', val);
})

watch(templateList, () => {
  initializeTemplate();
})

const onInputSubmit = (values) => {
  console.log('values', values);

  templateGenerate.generate(template.value.id, values, ({ inputId }) => {
    router.push(`/template/${templateKey.value}/${inputId}`);
    inputIdRef.value = inputId;
  });
}

</script>

<template>
  <div class="d-flex min-vh-100">
    <TemplateInput :inputs="formItems"
                  :template="template"
                  :values="inputValues"
                  @submit="onInputSubmit"
                  class="d-flex p-4 lg:min-w-[500px] min-w-1/2" />
<!--    <CPlaceholder-->
<!--        v-else-->
<!--        class="d-flex p-4 lg:min-w-[500px] min-w-1/2"-->
<!--        component="p"-->
<!--        animation="glow"-->
<!--    >-->
<!--      <CPlaceholder color="secondary" :xs="12" size="lg"/>-->
<!--      <CPlaceholder color="secondary" :xs="12" size="m"/>-->
<!--    </CPlaceholder>-->
    <div class="d-flex w-[1px] bg-slate-100"></div>
    <TemplateOutput :value="templateResponse"
                   class="d-flex flex-fill p-4 min-w-1/2"/>
  </div>
</template>
