<script setup lang="ts">
import SideMenu from "@/components/SideMenu/SideMenu.vue";
import {storeToRefs} from "pinia";
import {RouteLocationNormalizedLoaded, useRoute, useRouter} from "vue-router";
import {useUIStore} from "@/store/ui.ts";
import StateModal from "@/components/Atoms/Modal/StateModal.vue";
import {onMounted, ref} from "vue";
import SideMenuToggle from "@/components/SideMenuToggle.vue";
import {useI18n} from "vue-i18n";
import {useTokenStore} from "@/store/token.ts";

const uiUserStore = useUIStore();
const tokenStore = useTokenStore();

const {sideMenuVisible} = storeToRefs(uiUserStore);
const {toggleSideMenu} = uiUserStore;
const router = useRouter();
const route = useRoute();

const modalVisible = ref(false);

const state = ref('');
const label = ref('');
const {t} = useI18n();

const paymentStatusHandler = (route: RouteLocationNormalizedLoaded) => {
  if (route.query.payment) {
    state.value = route.query.payment as string;
    label.value = t(`payment.paid.${state.value}`);
    modalVisible.value = true;

    if (state.value === 'success') {
      tokenStore.getTokens();
    }
  }

  if (route.query.subscriptionCancel) {
    state.value = route.query.subscriptionCancel as string;
    label.value = t(`subscription.cancel.${state.value}`);
    modalVisible.value = true;
  }

  if (route.query.subscriptionRenew) {
    state.value = route.query.subscriptionRenew as string;
    label.value = t(`subscription.renew.${state.value}`);
    modalVisible.value = true;
  }

  if (route.query.subscriptionChange) {
    state.value = route.query.subscriptionChange as string;
    label.value = t(`subscription.change.${state.value}`);
    modalVisible.value = true;
  }
}

onMounted(async () => {
  paymentStatusHandler(route)
  sideMenuVisible.value = window.innerWidth >= 768;
})

const clickOutside = () => {
  if (sideMenuVisible.value) {
    toggleSideMenu(false);
  }
}

const toggleOpenSideMenu = () => {
  setTimeout(() => {
    toggleSideMenu(true);
  }, 10)
}
</script>

<template>
  <div class="side-menu-wrapper"
       v-click-out-side="clickOutside">
    <SideMenu v-model="sideMenuVisible"/>
    <SideMenuToggle class="d-lg-none d-md-none" @click="toggleOpenSideMenu"/>
  </div>

  <div class="wrapper d-flex flex-column">
    <StateModal :label="label" :state="state" v-model="modalVisible"/>

    <div class="vh-100 d-flex flex-column">
      <router-view></router-view>
    </div>
  </div>
</template>

<style lang="scss">
@import 'highlight.js/styles/a11y-dark.css';

$mobileMenuHeight: 70px;

.placeholder {
  border-radius: 5px;
  margin-bottom: 10px;

  &-lg {
    min-height: 250px !important;
  }

  &-m {
    min-height: 50px !important;
  }
}

@media (max-width: 768px) {
  .wrapper {
    padding-top: $mobileMenuHeight;
  }
  .side-menu-wrapper {
    position: fixed;
    width: 100%;
    height: $mobileMenuHeight;
    background: #1e1e1e !important;
    z-index: 999;
  }
}
@media (min-width: 768px) {
  .wrapper {
    padding-left: 16rem;
    /*background: #f7f7f7;*/
  }
}

.footer {
  font-size: 12px;
}
</style>
