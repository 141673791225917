<script setup lang="ts">

import {useRouter} from "vue-router"
import {ref, watch} from 'vue'
import Divider from "@/components/Divider.vue"
import {supabase} from "@/supabase.ts"
import {toast} from "vue3-toastify"
import StateMessage from "@/components/Atoms/Message/StateMessage.vue";
import {addEmailSubscriber} from "@/services/http.service.ts";
import {emailMessages} from "@/i18n/emailMessages.ts";
import {useI18n} from "vue-i18n";
import {useGtag} from "vue-gtag-next";
import {DOMAIN_URL} from "@/constants/variables.ts";
import Auth from "@/views/Auth.vue";
import {CFormLabel} from "@coreui/vue";

const fullName = ref('')
const email = ref('')
const errorEmail = ref('')
const errorGoogle = ref('')

const router = useRouter()
const {event} = useGtag()

const {t, locale} = useI18n();

const signUp = async () => {
  if (!email.value) {
    toast.error(t('user.error.email.required'))
    return;
  }

  if (!fullName.value) {
    toast.error(t('user.error.fullName.required'))
    return;
  }

  const signupResponse = await supabase.auth.signInWithOtp({
    email: email.value,
    options: {
      data: {
        full_name: fullName.value,
        i18n: emailMessages[locale.value],
      },
      emailRedirectTo: `${DOMAIN_URL}/intro/welcome`
    },
  });

  const error = signupResponse.error;

  if (!error) {
    event("sign_up", {
      method: "magic_link",
    });

    await router.push({
      name: 'emailConfirm',
      query: {
        email: email.value,
        type: 'signUp'
      }
    })

  } else {
    console.log('error', error);
    toast.error(t(`error.signup.${error.status}`))
    errorEmail.value = t(`error.signup.${error.status}`)
  }
}

// Possible to do it in a popup using `skipBrowserRedirect` and opening auth URL myself in popup
// https://github.com/orgs/supabase/discussions/4487
const signUpWithGoogle = async () => {
  const {data, error} = await supabase.auth.signInWithOAuth({
    provider: 'google'
  })

  if (!error) {
    event("sign_up", {
      method: "google"
    });

    router.push('/')
  } else {
    errorEmail.value = error.message
    console.error(error)
  }
}
</script>

<template>
  <Auth
      :header="$t('auth.signUp.header')"
      :subheader="$t('auth.signUp.subheader')"
  >
    <CForm @submit.prevent="signUp" class="d-flex flex-column">
      <CFormLabel for="email">
        <span class="required">*</span> {{ t('user.email') }}
        <CFormInput
            v-model="email"
            id="email"
            name="email"
            class="mb-2"
            type="email"
            placeholder="jiri.novak@seznam.cz"
        />
      </CFormLabel>
      <CFormLabel for="fullName">
        <span class="required">*</span> {{ t('user.full_name') }}
        <CFormInput
            v-model="fullName"
            id="fullName"
            name="fullName"
            autocomplete="name"
            class="mb-2"
            type="text"
            placeholder="Jiří Novák"
        />
      </CFormLabel>
      <CAlert class="w-100" v-if="errorEmail.length > 0" color="danger">{{ errorEmail }}</CAlert>
      <CButton class="w-100 mt-4" size="lg" color="primary" type="submit">{{ t('auth.signUp.create') }}</CButton>

      <CFormText class="mt-4 text-center">{{ t('auth.signUp.have_account') }}
        <router-link to="/sign-in">{{ t('auth.signUp.login') }}</router-link>
      </CFormText>
    </CForm>

    <Divider class="mb-4 mt-5" :text="$t('ux.or')"/>

    <CAlert class="w-100" v-if="errorGoogle.length > 0" color="danger">{{ errorGoogle }}</CAlert>

    <div class="d-flex justify-content-center">
      <button @click="signUpWithGoogle"
              class="mb-2 sign-up__oauth" size="lg"
      >
        <img src="/public/icons/google.png" alt="google"/>
      </button>
    </div>
  </Auth>
</template>

<style lang="scss">

.sign-up__oauth {
  background: none;
  border: none;

  img {
    width: 40px;
    height: 40px;
  }
}
</style>
