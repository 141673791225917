<script setup lang="ts">
import Avatar from "@/components/Atoms/Avatar/Avatar.vue";
import {AIContentTypes, OpenAIRole, useChatStore} from "@/store/chat.ts";
import {storeToRefs} from "pinia";
import {useUserStore} from "@/store/user.ts";
import ImageItem from "@/components/templates/Chat/ContentItem/ImageItem.vue";
import TextItem from "@/components/templates/Chat/ContentItem/TextItem.vue";
import ContentItemActions from "@/components/templates/Chat/ContentItem/ContentItemActions.vue";
import {inject} from "vue";

const {id, feedbackOk, content, role, type, status, isLast} = defineProps<{
  id: number,
  content: string,
  feedbackOk: boolean,
  type: string,
  role: string,
  isLast: string,
  status: string
  suggestions?: Object,
}>();

const chatId = inject('chatId');

const {generatingInProgress} = storeToRefs(useChatStore());

const userStore = useUserStore();
const {user} = storeToRefs(userStore);

</script>

<template>
  <div
      class="content-item d-flex flex-row justify-content-center"
      :class="{
        [role]: true,
        'content-item--last': isLast,
        'content-item__response': role === OpenAIRole.ASSISTANT
      }"
  >
    <div class="content-item__content d-flex  flex-column flex-lg-row w-100">
      <section class="d-flex flex-row w-100">
        <Avatar :role="role"
                class="me-3"
                size="sm"/>

        <template v-if="role === OpenAIRole.ASSISTANT">
          <ImageItem v-if="type === AIContentTypes.IMAGE"
                     :content="content"
                     :status="status"/>
          <TextItem v-else
                    :content="content"
                    :is-last="isLast"
                    :type="type"
                    :suggestions="suggestions"/>
        </template>

        <template v-if="role === OpenAIRole.USER">
          <TextItem class="p-0 w-100 mx-3"
                    :content="content"
                    :suggestions="[]"/>
        </template>
      </section>

      <template v-if="role === OpenAIRole.ASSISTANT">
        <div :style="{visibility: !generatingInProgress?.[chatId]?.content || !isLast ? 'visible': 'hidden'}">
          <ContentItemActions class="content-item__actions flex-column d-flex"
                              :id="id"
                              :feedbackOk="feedbackOk"
                              :content="content"
                              :status="status"
                              :type="type"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style lang="scss">
.content-item {
  width: 100%;
  border-top: 1px solid #efefef;
  padding: 20px 30px;

  &:first-child {
    border-top: none;
  }

  &__response {
    background: #fafafa;
  }

  &__content {
    position: relative;
    max-width: 900px;
    width: 100%;
    margin-left: 10px;

    p {
      padding: 0 0 12px;
    }
  }

  @media(max-width: 768px) {
    .avatar.assistant {
      display: none;
    }

    &.assistant {
      .content-item__content {
        padding-top: 10px;
      }
    }

    &__content {
      margin-left: 0;
      padding-left: 10px !important;
      padding-right: 10px !important;
    }

    &-actions {
      position: relative !important;
      width: 100%;
    }
  }
}
</style>