<script setup lang="ts">
import {useRouter} from "vue-router";
import {storeToRefs} from "pinia";
import {computed, ref} from "vue";
import {useTokenStore} from "@/store/token.ts";
import {formatDate} from "@/util/date.ts";
import {CTableDataCell} from "@coreui/vue";

const router = useRouter();

const tokenStore = useTokenStore();
useTokenStore().getTokensMovement();
const {tokenMovements} = storeToRefs(tokenStore);

const dateTimeOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  second: "2-digit",
};

const tableItems = computed(() => tokenMovements.value.map(({created_at, reason, token_count}) => (
    {
      created_at: formatDate(created_at),
      reason,
      token_count,
      _cellProps: {id: {scope: 'row'}},
    }
)))

</script>

<template>
  <h2>{{ $t('settings.tokens.pricing.header') }}</h2>
  <CTable>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col">{{$t('settings.tokens.pricing.contentType.header') }}</CTableHeaderCell>
        <CTableHeaderCell scope="col"></CTableHeaderCell>
        <CTableHeaderCell scope="col">{{$t('settings.tokens.pricing.price.header') }}</CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      <CTableRow>
        <CTableDataCell>{{$t('settings.tokens.pricing.contentType.image.text') }}</CTableDataCell>
        <CTableDataCell>&nbsp;</CTableDataCell>
        <CTableDataCell>{{$t('settings.tokens.pricing.contentType.image.value') }}</CTableDataCell>
      </CTableRow>
      <CTableRow>
        <CTableDataCell>{{$t('settings.tokens.pricing.contentType.text.text') }}</CTableDataCell>
        <CTableDataCell>&nbsp;</CTableDataCell>
        <CTableDataCell>{{$t('settings.tokens.pricing.contentType.text.value') }}</CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>
  <h2 class="mt-5">{{ $t('settings.tokens.table.header') }}</h2>
  <CTable>
    <CTableHead>
      <CTableRow>
        <CTableHeaderCell scope="col">{{$t('settings.tokens.table.createdAt') }}</CTableHeaderCell>
        <CTableHeaderCell scope="col">{{$t('settings.tokens.table.reason') }}</CTableHeaderCell>
        <CTableHeaderCell scope="col">{{$t('settings.tokens.table.count') }}</CTableHeaderCell>
      </CTableRow>
    </CTableHead>
    <CTableBody>
      <CTableRow v-for="item in tableItems">
        <CTableDataCell>{{ item.created_at }}</CTableDataCell>
        <CTableDataCell>
          <CBadge class="settings-tokens__content-type" :color="item.token_count < 0 ? 'dark' : 'success'">{{ $t(`contentType.${item.reason}`) }}</CBadge>
        </CTableDataCell>
        <CTableDataCell>{{ item.token_count }}</CTableDataCell>
      </CTableRow>
      <CTableRow v-if="!tableItems.length">
        <CTableDataCell class="text-center p-5" colspan="4">{{ $t(`tokens.noTokens`) }}</CTableDataCell>
      </CTableRow>
    </CTableBody>
  </CTable>
</template>

<style lang="scss">
  .settings-tokens {
    &__content-type {
      &.bg-primary {
        color: white;
      }
    }
  }
</style>