<script setup lang="ts">
import {Status} from "@backend/functions/_shared/model/general.ts";
import {AIContentTypes, useChatStore} from "@/store/chat.ts";
import {downloadImageSupabase} from "@/supabase.ts";
import {downloadBlob} from "@/util/file.ts";
import {copyToClipboard} from "@/util/clipboard.ts";
import {toast} from "vue3-toastify";
import {useI18n} from "vue-i18n";
import {inject, ref} from "vue";
import FeedbackModal from "@/components/templates/Chat/ContentItem/FeedbackModal.vue";
import {IFeedbackPayload, saveFeedback} from "@/services/http.service.ts";
import {useGtag} from "vue-gtag-next";

const {id, content, feedbackOk, status, type} = defineProps<{
  id: number,
  content: string,
  feedbackOk: boolean,
  status: string
  type: string
}>();

const downloadImage = async (imagePath: string) => {
  const response = await downloadImageSupabase(imagePath);

  await downloadBlob(response.data, imagePath);
}
const {t} = useI18n()

const feedbackModalVisible = ref(false)
const {setMessageFeedbackById} = useChatStore();
const copyToClipboardHandler = (content) => {
  copyToClipboard(content);
  toast.success(t(`ui.clipboard.success`));
}
const chatId = inject('chatId');

const {event} = useGtag();

const giveFeedback = ({ok}) => {
  if (!ok) {
    feedbackModalVisible.value = !ok;
  } else {
    saveFeedback({
      responseId: id,
      feedbackOk: true,
      feedbackText: '',
      feedbackHelpful: null,
      feedbackTruthful: null,
    });

    event('feedback_positive')

    setMessageFeedbackById(chatId.value, id, true);
  }
}

const onFeedbackSubmit = (payload: IFeedbackPayload) => {
  saveFeedback(payload);

  event('feedback_negative')

  feedbackModalVisible.value = false;

  setMessageFeedbackById(chatId.value, id, payload.feedbackOk);
}

</script>

<template>
  <FeedbackModal
      :responseId="id"
      :content-type="type"
      @submit="onFeedbackSubmit"
      v-model="feedbackModalVisible"/>

  <section
      class="content-item-actions flex-lg-column d-flex flex-md-row"
  >
    <template v-if="type === AIContentTypes.IMAGE">
      <template v-if="status === Status.SUCCESS">
        <CIcon icon="cilCloudDownload"
               class="content-item-actions__icon"
               size="lg"
               @click="() => downloadImage(content)"/>
      </template>
    </template>

    <template v-else>
      <CIcon icon="cilCopy"
             class="content-item-actions__icon"
             size="lg"
             @click="() => copyToClipboardHandler(content)"/>
    </template>
    <div
        class="flex-lg-column d-flex flex-md-row tour-feedback-icons"
    >
      <div :class="feedbackOk === true ? 'content-item-actions__icon--positive-active': ''">
        <CIcon icon="cilThumbUp"
               class="content-item-actions__icon"
               @click="() => giveFeedback({ ok: true })"/>
      </div>

      <div :class="feedbackOk === false ? 'content-item-actions__icon--negative-active': ''">
        <CIcon icon="cilThumbDown"
               class="content-item-actions__icon"
               @click="() => giveFeedback({ ok: false })"/>
      </div>
    </div>
  </section>
</template>

<style lang="scss">
.content-item-actions {
  flex: 0 0 auto;
  padding-left: 20px;

  &__icon {
    color: var(--cui-primary);
    padding: 5px;
    border-radius: 50%;
    width: 32px !important;
    height: 32px !important;
    cursor: pointer;
    margin-bottom: 3px;
    opacity: 0.6;

    &--positive-active {
      .icon {
        opacity: 1;
        background-color: rgb(228 247 196) !important;
        color: #77b40d !important;
      }
    }

    &--negative-active {
      .icon {
        opacity: 1;
        background-color: rgba(254, 226, 226, 1) !important;
        color: rgb(220, 38, 38) !important;
      }
    }

    &:hover {
      opacity: 1;
      background: white;
      box-shadow: 0 0 9px -1px rgb(0, 0, 0, 0.1) !important;
    }
  }

  @media(max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
    top: -10px;
    position: absolute;
    right: 10px;
    justify-content: end;
  }
}
</style>